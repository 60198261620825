import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { interval, Subscription } from 'rxjs';
import { SubjectService } from '../../services/subject.service';
import { CommonService } from '../../services/common.service';
import { environment } from "../../../environments/environment";
import { AlertService } from 'src/app/services/alert.service';
import { GlobalService } from '../../services/global.service';

interface NotificationDetails {
is_admin: boolean;
msg_body: string;
url: string;
}
 interface Notification {
  id: number;
  is_read: boolean,
  last_read: string,
  msg_body: string,
  name: string,
  notif_date_send: string,
  notification_detail: NotificationDetails,
  profile_image: string,
  user_id: number,
 }
interface Tabs{
  id: number,
  name: string;
  label: string;
  data:Array<Notification>;
}
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationsComponent implements OnInit, OnDestroy {
  loggedInUserId: number;
  notifications: any = [];
  loader: boolean = false;
  lang: number = 1;
  subscription: Subscription;
  unreadCount: number=0;
  showOnlyUnread: boolean = false;
  assignedRoles: any = [];
  isUserAdmin:boolean;
  tabs:Array<Tabs> =[{
    id:1,
    name:'admin',
    label:'Admin',
    data:[]
  },{
    id:2,
    name:'user',
    label:'User',
    data:[]
  }]
  redirectEnvUrl = environment.miApp;
  constructor(private api: ApiService,
    private subject: SubjectService,
    private fnc: CommonService,
    private alert:AlertService,
    private global: GlobalService,
    @Inject(PLATFORM_ID) private platformId,) {
      this.loggedInUserId = this.api.user_id;
    this.subject.assignedRoles.subscribe(res => {
      if (res?.length > 0) {
        this.assignedRoles = res;
      }
    })
    this.subject.callNotifications.subscribe(value => {
      if (value) {
        this.getNotifications();
      }
    })
    this.isUserAdmin= this.global.checkResourceAccess('notification').GET ? true : false;
    this.getNotifications();
    const tenMinsInMs = 1000 * 60 * 10; //in milliseconds
    const source = interval(tenMinsInMs);
    this.subscription = source.subscribe(val => this.getNotifications());
  }

  showAllUnread(event) {
    const eventIs=event.checked!=null || undefined ? event.checked : event;
    if (eventIs) {
      this.loader=true;
      this.showOnlyUnread = eventIs;
      this.api.getMiData(`common-notification?user_id=${Number(this.api.user_id)}&is_admin=${this.isUserAdmin}`).subscribe((res: any) => {
        this.loader=false;
        if (res && res.status == 200) {
          let temp=[], temp_admin = [], temp_user = [],forOnlyCount=[];
          res.data.map(element => {
            if (element.msg_body && element.is_read == false) {
              const data:Notification = {
                id: element.id ? element.id : '',
                is_read: element.is_read ? element.is_read : false,
                last_read: element.last_read ? element.last_read : '',
                msg_body: element.msg_body ? element.msg_body.replace(/\\n/g, '<br/>') : '',
                name: element.name ? element.name : '',
                notif_date_send: element.notif_date_send ? this.getTimeString(element.notif_date_send) : '',
                notification_detail: element.notification_detail ? element.notification_detail : '',
                profile_image: element.profile_image ? element.profile_image : '',
                user_id: element.user_id ? element.user_id : '',
              }
              if(!this.isUserAdmin && data.user_id == this.loggedInUserId) temp.push(data); // if user not admin only show his notifications without tabular display
              if(this.isUserAdmin){ // if user admin show all users notifications in admin tab
                if(data.notification_detail.is_admin)temp_admin.push(data);
                if(data.user_id == this.loggedInUserId){ temp_user.push(data) }
              }
            }
          });
          if(!this.isUserAdmin)this.notifications = temp;
          if(temp_user.length >= 0)this.tabs.filter(tab=>tab.id==1).map(tab=>tab.data=temp_admin);
          if(temp_admin.length >= 0)this.tabs.filter(tab=>tab.id==2).map(tab=>tab.data=temp_user);
          this.unreadCount = forOnlyCount.length;
        }
      })
    } else {
      this.showOnlyUnread = eventIs;
      this.getNotifications(false);
    }

  }

  getNotifications(loader?: boolean) {
    this.loader=loader;
    this.api.getMiData(`common-notification?user_id=${Number(this.api.user_id)}&is_admin=${this.isUserAdmin}`).subscribe((res: any) => {
      this.loader=false;
      if (res && res.status == 200) {
        let temp=[], temp_admin = [], temp_user = [],forOnlyCount=[];
        res.data.map(element => {
          if (element.msg_body) {
            const data:Notification = {
              id: element.id ? element.id : '',
              is_read: element.is_read ? element.is_read : false,
              last_read: element.last_read ? element.last_read : '',
              msg_body: element.msg_body ? element.msg_body.replace(/\\n/g, '<br/>') : '',
              name: element.name ? element.name : '',
              notif_date_send: element.notif_date_send ? this.getTimeString(element.notif_date_send) : '',
              notification_detail: element.notification_detail ? element.notification_detail : '',
              profile_image: element.profile_image ? element.profile_image : '',
              user_id: element.user_id ? element.user_id : '',
            }
            if(!data.is_read){ forOnlyCount.push(data);}
            if(!this.isUserAdmin && data.user_id == this.loggedInUserId) temp.push(data);  // if user not admin only show his notifications without tabular display
            if(this.isUserAdmin){ // if user admin show all users notifications in admin tab
              if(data.notification_detail.is_admin)temp_admin.push(data);
              if(data.user_id == this.loggedInUserId){
                temp_user.push(data)
              }
            }
          }
        });
        if(!this.isUserAdmin) this.notifications = temp;
        if(temp_user.length >= 0)this.tabs.filter(tab=>tab.id==1).map(tab=>tab.data=temp_admin);
        if(temp_admin.length >= 0)this.tabs.filter(tab=>tab.id==2).map(tab=>tab.data=temp_user);
        this.unreadCount = forOnlyCount.length;
      }else{
      }
    })
  }

  markRead(row, event, isReadAll: boolean = false) {
    if (row && row.is_read == true) {
      return;
    } else {
      if (event) {
        event.stopPropagation();
      }
      let url = `common-notification-read-unread`;
      const body = {
        user_id: Number(this.api.user_id),
        id: isReadAll ? "" : row?.id,
        is_read: true,
        is_admin: this.isUserAdmin
      }
      this.api.postMiData(url, body).subscribe((res: any) => {
        if (res && res.status == 201) {
          this.alert.success(res.message);
          this.showAllUnread(this.showOnlyUnread);
        }
      })
    }

  }

  getTimeString(value) {
    if (isPlatformBrowser(this.platformId)) {
      if (value) {
        const currentTime: any = new Date();
        let responseTime: any = new Date(value);
        responseTime.setHours(responseTime.getHours() + 5, responseTime.getMinutes() + 30);
        const diff_in_time= Math.abs(currentTime.getTime() - responseTime.getTime())
        const res = diff_in_time / 1000;
        //get days
        const days = Math.floor(res / 86400);
        // get hours
        const hours = Math.floor(res / 3600) % 24;
        // get minutes
        const minutes = Math.floor(res / 60) % 60;
        // get seconds
        const seconds = res % 60;
        let unit
        if (days && days >= 1) {
          unit =''
        }
        else if (hours) {
          if (hours > 1) {
            unit = 'hours ago';
          } else {
            unit = 'hour ago';
          }
        } else if (!hours && minutes) {
          if (minutes > 1) {
            unit = 'minutes ago';
          } else {
            unit = 'minute ago';
          }
        } else if (!hours && !minutes) {
          if (seconds > 1) {
            unit = 'seconds ago';
          } else {
            unit = 'second ago';
          }
        }
        return `${days >= 1 ? this.fnc.formatDateUTC(responseTime):hours > 0 ? hours : minutes > 0 ? minutes : 'Just now'} ${hours > 0 || minutes > 0 || seconds > 59 ? unit : ''}`
      }
    }
  }

  menuClosed() {
    this.getNotifications();

  }

  ngOnInit(): void {
    this.lang = this.api.language;
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
