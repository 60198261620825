import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MiHomeComponent } from './mi-home.component';
import { ProductsComponent } from './products/products.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutComponent } from './about/about.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [{
  path: '', component: MiHomeComponent,
  children: [
    {
      path: 'login',
      component: LoginComponent,
    },
    {
      path: '',
      component: LoginComponent,
    },
    {
      path: 'products',
      component: ProductsComponent,
    },
    {
      path: 'contact',
      component: ContactUsComponent,
    },
    {
      path: 'about',
      component: AboutComponent,
    },

  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MiHomeRoutingModule { }
