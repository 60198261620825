import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { BrowserService } from './browser.service';

@Injectable()
export class SubjectService {

  constructor(private browser:BrowserService) {
    if (!this.browser.getLocalValue('user_company')) {
      this.browser.setLocalValue('user_company','Public');
    }
    if (!this.browser.getLocalValue('language_id')) {
      this.browser.setLocalValue('language_id','1');
    }
  }
  openFilter = new Subject<boolean>();
  profileImage = new BehaviorSubject<any>(this.browser.getLocalValue('profile_image'));
  userName = new BehaviorSubject<any>(this.browser.getLocalValue('uname'));
  userInfo = new BehaviorSubject<any>(this.browser.getLocalValue('user_info')?JSON.parse(this.browser.getLocalValue('user_info')):'');
  cityList = new BehaviorSubject<any>(this.browser.getLocalValue('city_list'));
  langList = new BehaviorSubject<any>(this.browser.getLocalValue('lang_list'));
  assignedRoles =new BehaviorSubject<any>(this.browser.getLocalValue('roles') ?this.browser.getLocalValue('roles'):'' )
  selectedGroupName = new BehaviorSubject<any>(null);
  selectedLanguageForApp = new BehaviorSubject<any>(this.browser.getLocalValue('language_id'));
  selectedLanguageCodeForApp = new BehaviorSubject<any>('en');
  selectedCompany=new BehaviorSubject<any>(this.browser.getLocalValue('user_company'));
  reportsLoader= new BehaviorSubject<boolean>(false);
  projVerData= new BehaviorSubject<any>('');
  projDataAttrItems = [];
  selectedProjVer=new BehaviorSubject<any>('');
  selectedProject=new BehaviorSubject<any>('');
  selectedMallProject=new BehaviorSubject<any>('');
  loader=new BehaviorSubject<boolean>(false);
  initialAppLoader=new BehaviorSubject<boolean>(false);
  internalMiNeigh= new BehaviorSubject<any>('');
  csvUploadAccess=new BehaviorSubject<any>('');
  mallVerData=new BehaviorSubject<any>('');
  // reportName=new BehaviorSubject<any>('');
  // for reports
  public unit_history_obj:any;
  public propertyWmsBounds= new BehaviorSubject<any>('');
  callNotifications=new BehaviorSubject<any>('');
}
