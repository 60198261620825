import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
@Injectable()
export class InterceptorService {

  constructor(private route: Router,  private alertService: AlertService,
    @Inject(PLATFORM_ID) private platformId: any, 
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                if ([403].includes(event?.body?.status)) {
                    if (isPlatformBrowser(this.platformId)) {
                        if(request.url && request.url.includes('/user/resource')){
                            localStorage.clear();
                            this.route.navigate(['/']);
                        }
                    }                   
                }
            }
            return event;
        }),
        catchError((error: HttpErrorResponse) => {
            if([403].includes(error.status)){
                if (isPlatformBrowser(this.platformId)) {                   
                    if(request.url && request.url.includes('/user/resource')){
                        localStorage.clear();                       
                        this.route.navigate(['/']);
                    }
                }              

            }else{
                if(request.url && !request.url.includes('redis-keys')){
                    this.alertService.error(error.error.message, "warn");
                }
            }
            return throwError(() => error)
        })
    );
}
}
